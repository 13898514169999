@import url(./carousel.css);
@import url(./features.css);
@import url(./section4.css);
@import url(./contacts.css);
@import url(./modal.css);

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, "Inter", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
