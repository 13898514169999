.carousel-container {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3.8rem 0rem 0rem 0rem;
}

.carousel-block {
  background-color: #1f1c26;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 4rem 1.5rem; */
  border-radius: 1rem;
  color: white;
}

.carousel-image {
  background-color: #1f1c26;
  width: 100%;
  /* object-fit: cover; */
}

.carousel-detail {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #1f1c26;
  padding: 2rem;
}

.carousel-tag {
  background: linear-gradient(90deg, #8cebe0 0%, #45b1ff 100%);
  font-size: 1rem;
  font-family: "Anonymous Pro", monospace;
  border-radius: 6.25rem;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  color: black;
  text-align: center;
}

.carousel-title {
  font-weight: 800;
  font-size: 1.5rem;
}

.carousel-description {
  font-weight: 400;
  font-size: 1rem;
}

.dot {
  background-color: #78fd96;
  height: 12px;
  width: 12px;
  border-radius: 10rem;
  margin-right: 0.75rem;
  cursor: pointer;
}

.dot-line {
  background-color: transparent;
  height: 12px;
  width: 12px;
  border-radius: 10rem;
  border: 1px solid white;
  margin-right: 0.75rem;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .carousel-container {
    padding: 10rem 0rem 0rem 0rem;
  }

  .carousel-block {
    flex-direction: row;
    /* padding: 3.75rem 2.875rem; */
    gap: 1.5rem;
  }
  .carousel-image {
    /* max-width: 25rem; */
  }

  .carousel-detail {
    text-align: left;
    max-width: 50%;
  }

  .carousel-tag {
    font-size: 1.5rem;
    padding: 0.875rem 1.5rem;
    margin-top: 0rem;
    text-align: center;
    width: fit-content;
  }

  .carousel-title {
    font-size: 2rem;
  }

  .carousel-description {
    margin-top: 0.75rem;
  }
}

@media only screen and (min-width: 1441px) {
  .carousel-block {
    gap: 3.5rem;
  }
  .carousel-image {
    /* max-width: 39rem; */
    /* width: 80%;
    aspect-ratio: 630 / 423; */
  }
}
