.contacts-container {
  background-color: transparent;
  color: white;
  font-size: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  max-width: 1500px;
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}

.contacts-socials,
.contacts-links {
  display: flex;
  align-items: center;
  z-index: 5;
}

.contacts-socials {
  justify-content: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contacts-links {
  grid-column: 2 / span 2;
  padding: 4.5rem 2rem 2rem 8rem;
  display: flex;
  align-items: start;
  gap: 6.375rem;
}

.contacts-logo {
  /* width: 25.8125rem; */
  max-width: 22rem;
  margin-bottom: 7.5rem;
  margin-left: -1rem;
}

.contacts-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.contacts-icon {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.contacts-copyright-email {
  font-size: 1.25rem;
  color: #a0a0a0;
}

.contacts-links-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contacts-links-column-header {
  font-size: 1.75rem;
  font-weight: 800;
}

.contacts-link {
  font-size: 1rem;
  cursor: pointer;
}

.footer-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .contacts-container {
    display: flex;
    flex-direction: column;
  }

  .contacts-socials {
    flex-direction: column;
    align-items: center;
  }

  .contacts-links {
    justify-content: center;
    padding: 1.5rem;
    gap: 3rem;
  }

  .contacts-logo {
    width: 100%;
    object-fit: contain;
    margin: 0rem 1rem;
  }

  .contacts-links-column {
    gap: 1.5rem;
  }
}

@media only screen and (max-width: 425px) {
  .contacts-links {
    flex-direction: column;
  }
}
