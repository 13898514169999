.section-4-container {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: -3rem 0 10rem 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.section-4-card {
  /* width: 96rem; */
  /* height: 23rem; */
  display: flex;
  flex-direction: row;
  max-width: 96rem;
}
.section-4-card-left {
  flex: 1;
  background: linear-gradient(225deg, #0c0c0c 0%, #2d0230 100%);
  border-top-left-radius: 2.125rem;
  border-bottom-left-radius: 2.125rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
}

.section-4-card-left-detail {
  padding: 2.9375rem 4.375rem;
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-style: italic;
  font-weight: 700;
  font-size: 5rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  line-height: 6.25rem;
}

.section-4-card-right {
  flex: 1;
  background-color: #1f1c26;
  border-top-right-radius: 2.125rem;
  border-bottom-right-radius: 2.125rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 2.9375rem 1.875rem 2rem 2.9375rem;
  gap: 1.25rem;
}

.section-4-card-right:hover {
  background: linear-gradient(0deg, #8754ff 0%, rgba(135, 84, 255, 0%) 100%);
}

.section-4-title {
  font-weight: 800;
  font-size: 2rem;
}

.section-4-description {
  font-size: 1rem;
}

/* @media only screen and (max-width: 1441px) { */
@media only screen and (max-width: 992px) {
  .section-4-card {
    flex-direction: column;
  }

  .section-4-card-left {
    border-top-left-radius: 2.125rem;
    border-top-right-radius: 2.125rem;
    border-bottom-left-radius: 0rem;
  }

  /* .section-4-card-left-detail {
    padding: 2.9375rem 2.375rem;
    font-size: 6.875rem;
    line-height: 6.25rem;
  } */

  .section-4-card-right {
    border-bottom-left-radius: 2.125rem;
    border-bottom-right-radius: 2.125rem;
    border-top-right-radius: 0rem;
    color: white;
    padding: 2.9375rem 1.875rem 2rem 2.9375rem;
    gap: 1.25rem;
  }

  .section-4-description {
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 767px) {
  .section-4-card-left {
    border-top-left-radius: 2.125rem;
    border-top-right-radius: 2.125rem;
    border-bottom-left-radius: 0rem;
  }

  .section-4-card-left-detail {
    padding: 1rem;
    font-size: 4.25rem;
  }

  .section-4-card-right {
    padding: 2rem 1rem;
    gap: 1.25rem;
  }

  .section-4-title {
    font-size: 1.5rem;
  }

  .section-4-description {
    font-size: 1rem;
  }
}
