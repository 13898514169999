* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.lan-btn-content {
  position: absolute;
  top: 30px;
  right: 0;
  min-width: 100px;
}

.nav-btn-content {
  z-index: 998;
  background-color: white;
  border: transparent;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(26, 26, 26);
  color: white;
}

.lan-list {
  list-style-type: none;
  padding: 0rem;
  margin: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.lan-list > li {
  cursor: pointer;
}

.button-gradient {
  color: white;
  font-family: "Anonymous Pro";
  background: linear-gradient(to right, #ff96d6, #421c87);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  border-radius: 2rem;
}
.button-line {
  color: white;
  font-family: "Anonymous Pro";
  background: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  border-radius: 2rem;
  border: 2px solid white;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 86px;
  width: 100%;
  position: fixed;
  z-index: 9;
  padding: 0 2rem;
  backdrop-filter: blur(7px);
}

.header-logo {
  height: 44px;
}

.hero-gradient-bg {
  max-width: 600px;
}

.mobile-menu {
  display: none;
}

.slogan-title {
  font-size: 7rem;
  font-family: "Montserrat";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.community-wrapper {
  color: white;
  cursor: pointer;
}

.community-wrapper-1 {
  background-image: url("/public/bg/bg-line-1.svg");
  background-repeat: no-repeat;
  background-size: 320px auto;
  margin-bottom: 2rem;
  height: 218px;
}

.community-wrapper-1:hover {
  color: #e67ffa;
  background-image: url("/public/bg/bg-line-1-full.svg");
}

.community-wrapper-2 {
  background-image: url("/public/bg/bg-line-2.svg");
  background-repeat: no-repeat;
  background-size: 320px auto;
  height: 260px;
}

.community-wrapper-2:hover {
  color: #78fd96;
  background-image: url("/public/bg/bg-line-2-full.svg");
}

.community-wrapper-3 {
  background-image: url("/public/bg/bg-line-3.svg");
  background-repeat: no-repeat;
  background-size: 320px auto;
  margin-bottom: 2rem;
  height: 326px;
}

.community-wrapper-3:hover {
  color: #7a07fe;
  background-image: url("/public/bg/bg-line-3-full.svg");
}

.community-wrapper-4 {
  background-image: url("/public/bg/bg-line-4.svg");
  background-repeat: no-repeat;
  background-size: 320px auto;
  height: 256px;
}

.community-wrapper-4:hover {
  color: #5ef8ee;
  background-image: url("/public/bg/bg-line-4-full.svg");
}

.community-title {
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
}
.community-section-2 {
  margin-right: 1rem;
}

@media only screen and (max-width: 800px) {
  .desktop-menu {
    display: none !important;
  }
  .header-logo {
    height: 30px;
  }
  .header-wrapper {
    padding: 0 1rem;
  }
  .hero-gradient-bg {
    width: 300px;
  }
  .mobile-menu {
    display: unset;
  }
  .hero-title {
    font-size: 3rem !important;
  }
  .slogan-title {
    font-size: 3.5rem;
  }
  .community-section-2 {
    margin-right: 0rem;
  }

  .community-wrapper-1 {
    margin-top: 2rem;
  }

  .community-wrapper-2 {
    margin-bottom: 2rem;
  }

  .community-wrapper-3 {
    margin-top: 2.5rem;
    margin-bottom: 0rem;
  }
}
