.features-btn {
  font-family: "Anonymous Pro";
  font-weight: 700;
  font-style: italic;
  font-size: 1.75rem;
  border-radius: 2rem;
  text-align: center;
  padding: 0.5rem 2rem;
  margin-right: 1.5rem;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
}

.features-title {
  font-family: "Barlow Condensed";
  font-weight: 600;
  font-style: italic;
  font-size: 5rem;
  color: white;
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 800px) {
  .features-carousel-mobile {
    flex-direction: column;
  }
  .features-carousel-mobile-img {
    padding: 1rem;
    width: 100% !important;
  }
}
