.modal {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 480px;
  margin: auto;
}

.modal-bg {
  background-color: #292929;
  border-radius: 1rem;
  position: relative;
  color: white;
}

.modal-container {
  /* width: 70dvw; */
  max-width: 350px;
  font-family: "Noto Sans", sans-serif;
}

.modal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #c2c2c2;
  cursor: pointer;
}

.transparent-bordered-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  width: 6.25rem;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
  border: transparent;
  height: 1.75rem;
  background-color: #fffefe;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.2);
}
